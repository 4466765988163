@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './loader.css';
@import './utils.css';

:root {
    --priamryColor: #00aeef;
    --priamryColorHover: #006f97;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto';
}

.wrap {
    width: 100%;
    margin: auto;
    max-width: 500px;
}

a {
    color: var(--priamryColor) !important;
    text-decoration: none !important;
}

.btn {
    display: inline-block;
    cursor: pointer; 
    padding: 0.375rem 1.75rem;
    font-size: 14px;
    font-weight: 400;
     color: #fff;
     background-color: #000;
     border: 1px #000 solid;
     border-radius: 8px;
     text-align: center;
     text-decoration: none;
     user-select: none;
     vertical-align: middle;
     line-height: 1.5;
}
    
.btn-primary {
    border-color: var(--priamryColor);
    background-color: var(--priamryColor);
}

.btn-primary:hover {
    background-color: var(--priamryColorHover) !important;
    border-color: var(--priamryColorHover) !important;
}

a {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.44px;
    color: #4200FF;
    text-decoration: none;
}

.form-container {
    max-width: 95vw;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.display-container {
    margin-bottom: 15px;
}

.form-container select option {
    font-family: 'Roboto' !important;
    color: #000;
    background-color: #fff;
}

.form-label {
    font-size: 15px;
    letter-spacing: .4px;
    line-height: 1.5;
    padding-bottom: calc(0.375rem + 1px);
    padding-top: calc(0.375rem + 1px);
    margin-bottom: 0.2rem;
    display: inline-block;
}

.display-label {
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
}

.form-input {
    font-family: 'Roboto';
    width: 100%;
    background-color: #f8f8ff;
    border: 1px solid #b9c1cc;
    box-shadow: none!important;
    transition: all .3s ease;
    color: #212529;
    outline: 0;
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border-radius: 0.375rem;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
}

.display-input {
    font-family: 'Roboto';
    width: 100%;
    color: #212529;
    font-size: 1rem;
    font-weight: 400;
}

.form-input.error {
    border-color: red;
}

/* width */
textarea.form-input::-webkit-scrollbar {
    width: 3px;
}
  
/* Track */
textarea.form-input::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DADADA;
    border-radius: 10px;
}
  
/* Handle */
textarea.form-input::-webkit-scrollbar-thumb {
    background: var(--priamryColor)
}

.show-hide {
    position: absolute;
    right: 20px;
    top: 48px;
}

.show-hide span {
    color: var(--priamryColor);
    cursor: pointer;
    font-size: 13px;
}

.form-input::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.44px;
    color: rgba(0, 0, 0, 0.6);
}

.form-input:focus {
    outline: none;
}

.form-checkbox-group {
    position: relative;
    margin-top: 2px;
}

.checkbox-label {
    cursor: pointer;
    padding-left: 25px;
    font-size: 15px;
    letter-spacing: .4px;
    display: inline-block;
    margin-bottom: 7px;
    margin-top: 7px;
    position: relative;

}

/* Hide the browser's default checkbox */
.form-checkbox-group input {
    opacity: 0;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 8px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #eee;
    cursor: pointer;
}

.checkmark.error {
    border: 1px solid red;
}
  
/* When the checkbox is checked, add a *color* background */
.form-checkbox-group input:checked ~ .checkmark {
    background-color: var(--priamryColor);
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form-checkbox-group input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.form-checkbox-group .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.text-area-note {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
}

.container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.mobile-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--priamryColor);
    box-shadow: 0 0 37px rgb(8 21 66 / 5%);
    padding: 0px 10px 0px 10px;
    z-index: 10;
}

.mobile-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    letter-spacing: -0.41px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.link-back {
    width: 32px;
    height: 32px;
    background-image: url(../icons/Back\ arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.link-logout {
    width: 32px;
    height: 32px;
    background-image: url(../icons/Sign_out.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    cursor: pointer;
    background-color: transparent; 
}

.switch {
    width: 32px;
    height: 32px;
    background-image: url(../icons/Refresh_2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    cursor: pointer;
    background-color: transparent; 
}

.switch-camera {
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 32px;
    height: 32px;
    background-image: url(../icons/Refresh_2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    cursor: pointer;
    background-color: transparent; 
}

.card {
    border-top-left-radius: 0;
    height: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 37px rgb(8 21 66 / 5%);
    max-width: 100vw;
    padding: 40px;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
}

/* ------------------------------------------- */
/* COMPONENTS */
/* ------------------------------------------- */

/* LOGIN */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    padding: 30px 0px;
}

.haveAcc {
    color: #898989;
    font-size: 14px;
    margin-bottom: 25px;
}

/* HOME */
.home-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #000;
    margin-bottom: 20px;
}

.home-areas {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: -0.41px;
    color: #000;
    margin-bottom: 14px;
}

.home-clients {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    letter-spacing: -0.41px;
    color: #000000;
    margin-bottom: 42px;
}

.home-clients ul {
    color: #9586A8;
    list-style: none;
}

/* QRCODE */
.qr-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
}

.qr-container > section,
.qr-container > section > div {
    height: 100%;
    width: 100%;
}

.qr-container > section > div > video {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: auto !important;
}

.qr-result {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: var(--priamryColor);
    border-radius: 10px;
    z-index: 5;
    padding: 15px;
    text-align: center;
}

.qr-result a {
    color: #fff !important;
    font-weight: 500;
}

/* TASKS */
.task-container {
    display: flex;
    flex-direction: column;
}

.task-list {
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

.task-list-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 29px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 18px;
}

.task-list-left {
    cursor: pointer;
}

.task-list-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.task-list-ss {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.task-list-client {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.task-list-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #BDBDBD;
}

.task-list-status {
    padding: 3px 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    color: #000000;
}

.task-list-status.reported { background: #E9E6FD; }
.task-list-status.preview { background: #ffc107; }
.task-list-status.in_progress { background: #0dcaf0; }
.task-list-status.checkup { background: #20c997; }
.task-list-status.in_approval { background: #20c997; }
.task-list-status.approved { background: #20c997; }
.task-list-status.archive { background: #20c997; }
.task-list-status.canceled { background: #fa5c5c; }

.task-create-gray {
    background-color: #D9D9D9;
    padding: 8px;
    margin-bottom: 20px;
}

.task-create-title {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}

/* FILES */
.file-content .files .file-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f6f7fb;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    display: inline-block;
    padding: 15px;
    position: relative;
    width: 31%;
}

.file-content .files .file-box .file-top {
    align-items: center;
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 5px;
    display: flex;
    font-size: 36px;
    height: 100px;
    justify-content: center;
}

.task-image {
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.file-content .files h6 {
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500!important;
    opacity: .9;
    word-break: break-all;
}

@media screen and (max-width: 500px) {
    .file-content .files .file-box {
        width: 47%;
    }
}

@media screen and (max-width: 350px) {
    .file-content .files .file-box {
        width: 100%;
    }
}