.d-flex { display: flex; }
.align-items-center { align-items: center; }
.align-items-end { align-items: end; }
.align-items-start { align-items: start; }
.justify-content-center { justify-content: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-start { justify-content: start; }
.justify-content-end { justify-content: end; }
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }

.text-center { text-align: center; }
.text-muted { color: #6c757d!important; }

.mb-0 { margin-bottom: 0px !important; }
.mb-1 { margin-bottom: 10px !important; }
.mb-2 { margin-bottom: 20px !important; }
.mb-3 { margin-bottom: 30px !important; }
.mb-4 { margin-bottom: 40px !important; }
.mb-5 { margin-bottom: 50px !important; }

.mt-0 { margin-top: 0px !important; }
.mt-1 { margin-top: 10px !important; }
.mt-2 { margin-top: 20px !important; }
.mt-3 { margin-top: 30px !important; }
.mt-4 { margin-top: 40px !important; }
.mt-5 { margin-top: 50px !important; }

.ms-0 { margin-left: 0px !important; }
.ms-1 { margin-left: 10px !important; }
.ms-2 { margin-left: 20px !important; }
.ms-3 { margin-left: 30px !important; }
.ms-4 { margin-left: 40px !important; }
.ms-5 { margin-left: 50px !important; }

.me-0 { margin-right: 0px !important; }
.me-1 { margin-right: 10px !important; }
.me-2 { margin-right: 20px !important; }
.me-3 { margin-right: 30px !important; }
.me-4 { margin-right: 40px !important; }
.me-5 { margin-right: 50px !important; }

.pb-1 { padding-bottom: 10px !important; }
.pb-2 { padding-bottom: 20px !important; }
.pb-3 { padding-bottom: 30px !important; }
.pb-4 { padding-bottom: 40px !important; }
.pb-5 { padding-bottom: 50px !important; }

.pt-1 { padding-top: 10px !important; }
.pt-2 { padding-top: 20px !important; }
.pt-3 { padding-top: 30px !important; }
.pt-4 { padding-top: 40px !important; }
.pt-5 { padding-top: 50px !important; }

.ps-1 { padding-left: 10px !important; }
.ps-2 { padding-left: 20px !important; }
.ps-3 { padding-left: 30px !important; }
.ps-4 { padding-left: 40px !important; }
.ps-5 { padding-left: 50px !important; }

.pe-1 { padding-right: 10px !important; }
.pe-2 { padding-right: 20px !important; }
.pe-3 { padding-right: 30px !important; }
.pe-4 { padding-right: 40px !important; }
.pe-5 { padding-right: 50px !important; }