.loader-wrap {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #000;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid var(--priamryColor);
    border-left: 4px solid transparent;
}

@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

/* -------------------------------------- */

.loader-box {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  transition: color .3s,border .3s,transform .3s,opacity .3s;
  vertical-align: middle;
  height: auto;
}

.loader-box .loader-3 {
  -webkit-animation: loader-03 1s linear infinite;
  animation: loader-03 1s linear infinite;
  border: 0.2em solid var(--priamryColor);
  border-bottom-color: transparent;
  border-radius: 50%;
  position: relative;
  height: 30px !important;
  width: 30px !important;
}

.loader-19 {
  border-top: .2em solid var(--priamryColor);
  border-right: .2em solid transparent;
  animation: loader-19 1s linear infinite;
  border-radius: 100%;
  position: relative;
}

.loader-box [class*=loader-] {
  color: inherit;
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  width: 50px;
}

@keyframes loader-03 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-19 {
  to {
      transform: rotate(360deg);
  }
}